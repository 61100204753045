import { Button, Card,Col,Modal,Row, Spin } from 'antd';
import { useHumorHistoryContext } from 'contexts/HumorHistoryContext';
import { useNavigate } from 'react-router-dom';

const CardHumor = ({ emoji, title, onClick }) => {
    return (
        <Col md={4} xs={12} className='flex text-center' style={{ width: "20%", padding: "5px", cursor: "pointer" }} onClick={onClick}>
            <div style={{ fontSize: "95px" }}>{emoji}</div>
            <div style={{ fontWeight: "bold", marginTop: "-10px", fontSize: "18px" }}>{title}</div>
        </Col>
    )
}

const getReasonMessage = (reason, navigate, onShowModal) => {
    const onFinish = (route) => {
        navigate(route);
        onShowModal(false);
        console.log('onFinish' + route);
    }

    switch (reason) {
        case 'health':
            return (<>
                Você sabia que através da Leegal você tem acesso a diversos serviços de saúde on-line!<br />
                <Button onClick={() => {onFinish('/app/health-and-wellness')}} type='primary' className='mt-2'>Clique aqui</Button>
            </>);
        case 'family':
            return (<>
                Com a Leegal você pode ter orientações que ajudam no seu dia a dia. Experimente agora!<br />
                <Button onClick={() => {onFinish('/app/guidelines')}} type='primary' className='mt-2'>Clique aqui</Button>
            </>);
        case 'study':
            return (<>
                A Leegal conta com especialistas que podem te ajudar. Só chamar!<br />
                <Button onClick={() => {onFinish('/app/guidelines')}} type='primary' className='mt-2'>Clique aqui</Button>
            </>);
        case 'financial':
            return (<>
                Conte com a Leegal para te ajudar!<br />
                <Button onClick={() => {onFinish('/app/guidelines')}} type='primary' className='mt-2'>Clique aqui</Button>
            </>);
        case 'work':
            return (<>
                Você sabia que o apoio psicológico pode te orientar através de um espaço de expressão e escuta? Experimente agora!<br />
                <Button onClick={() => {onFinish('/app/health-and-wellness')}} type='primary' className='mt-2'>Clique aqui</Button>
            </>);
        case 'i_dont_know':
            return (<>
                Fique tranquilo, estamos aqui para te ajudar.<br />
                <Button onClick={() => {onFinish('/app/guidelines')}} type='primary' className='mt-2'>Clique aqui</Button>
            </>);
        default:
            return '';
    }
}

const ReasonMessage = ({ reason, navigate, onShowModal }) => {
    return (
        <div className="text-center mt-5">
            <h3 className="mt-3 font-weight-bold w-50 m-auto">
                {getReasonMessage(reason, navigate, onShowModal)}
            </h3>
        </div>
    )
}

const CardMessage = () => {
    const { humorHistory, onUpdateHumorHistory, loading, onShowModal } = useHumorHistoryContext();
    const navigate = useNavigate();

    return (
        humorHistory?.type === 'very_sad' || humorHistory?.type === 'sad' ? (
            <div className="text-center">
                {
                    humorHistory?.reason === null ? (
                        <>
                        <h3 className="mt-3 font-weight-bold">O que esta te deixando preocupado hoje?</h3>
                        <Card style={{ height: "100%" }}>
                            <Spin spinning={loading} >
                                <Row>
                                    <Button onClick={() => onUpdateHumorHistory('health')} type="primary" size="large" style={{ width: "100%", marginBottom: "5px" }}>Questões de saúde</Button>
                                    <Button onClick={() => onUpdateHumorHistory('family')} type="primary" size="large" style={{ width: "100%", marginBottom: "5px" }}>Questões familiares</Button>
                                    <Button onClick={() => onUpdateHumorHistory('study')} type="primary" size="large" style={{ width: "100%", marginBottom: "5px" }}>Demandas estudantis</Button>
                                    <Button onClick={() => onUpdateHumorHistory('financial')} type="primary" size="large" style={{ width: "100%", marginBottom: "5px" }}>Questões financeiras</Button>
                                    <Button onClick={() => onUpdateHumorHistory('work')} type="primary" size="large" style={{ width: "100%", marginBottom: "5px" }}>Preocupações no trabalho</Button>
                                    <Button onClick={() => onUpdateHumorHistory('i_dont_know')} type="primary" size="large" style={{ width: "100%", marginBottom: "5px" }}>Não sei exatamente o que está me preocupando</Button>
                                </Row>
                            </Spin>
                        </Card>
                        </>
                    ) : (
                        <ReasonMessage reason={humorHistory?.reason} navigate={navigate} onShowModal={onShowModal} />
                    )
                }
            </div>
        ) : (
            <div className="text-center">
                <h3 className="mt-3 font-weight-bold">Obrigado por compartilhar como você está se sentindo hoje!</h3>
            </div>
        )
    );
}

const HumorGrid = () => {
    const { onCreateHumorHistory, loading, humorHistory } = useHumorHistoryContext();
    return(
        <Spin spinning={loading}>
            {
                humorHistory?.type !== undefined ? (
                    <CardMessage />
                ) : (
                    <div className="my-2">
                        <div className="text-center">
                            <h3 className="mt-3 font-weight-bold">Como você esta se sentindo hoje?</h3>
                        </div>
                        <Card style={{ height: "100%" }}>
                            <Row>
                                <Col md={2} xs={24}></Col>
                                <CardHumor emoji="😣" title="Estressado" onClick={() => onCreateHumorHistory('very_sad')} />
                                <CardHumor emoji="😔" title="Triste" onClick={() => onCreateHumorHistory('sad')} />
                                <CardHumor emoji="😐" title="Neutro" onClick={() => onCreateHumorHistory('neutral')} />
                                <CardHumor emoji="😄" title="Feliz" onClick={() => onCreateHumorHistory('happy')} />
                                <CardHumor emoji="😁" title="Muito Feliz" onClick={() => onCreateHumorHistory('very_happy')} />
                            </Row>
                        </Card>
                    </div>
                )
            }
        </Spin>
    );
}

export const Humor = ({ isModal }) => {
    const { showModal } = useHumorHistoryContext();

    return (
        isModal ? (
            <Modal
                centered
                open={showModal}
                footer={null}
                closable={false}
                width={800}
                
            >
                <HumorGrid />
            </Modal>
        ) : (
            <HumorGrid />
        )
    )
}


export default Humor;
