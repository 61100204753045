import React, { createContext, useContext, useEffect, useState } from "react";
import ApiService from "services/ApiService";
import { useNotificationHandlerContext } from "./Resource/NotificationHandlerContext";

export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const { notify } = useNotificationHandlerContext();
  const [user, setUser] = useState(null);
  const [navigation, setNavigation] = useState(null);
  const [conexaEmbed, setConexaEmbed] = useState(null);
  const [loading, onLoading] = useState(false);

  const Login = async ({email, password}) => {
    onLoading(true);
    return await ApiService
      .post("login", {
        email: email,
        password: password,
      })
      .then((resp) => {
        if (resp?.access_token !== undefined) {
          localStorage.setItem("@App:token", resp.access_token);
          localStorage.setItem("@App:user", JSON.stringify(resp.user));
          localStorage.setItem("@App:navigation", JSON.stringify(resp.navigation));
          localStorage.setItem("@App:conexa_embed", resp.conexa_embed_url);

          setConexaEmbed(resp.conexa_embed_url);
          setNavigation(resp.navigation);
          setUser(resp.user);
        } else {
          notify('error', 'authentication', resp.message);
          onLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.status === 401) {
          notify('error', 'authentication', "Sistema indisponível no momento.");
          onLoading(false);
        } else {
          notify('error', 'authentication', "Sistema indisponível no momento.");
          onLoading(false);
        }
      });
  };

  const Logout = () => {
    onLoading(false);
    setUser(null);
    localStorage.removeItem("@App:user");
    localStorage.removeItem("@App:access");
    localStorage.removeItem("@App:token");
    localStorage.removeItem("@App:navigation");
    localStorage.removeItem("@App:language");
    localStorage.removeItem("@App:conexa_embed");
  };

  /** Recuperando o usuário e token da sessão */
  useEffect(() => {
    const storagedUser = localStorage.getItem("@App:user");
    const storagedToken = localStorage.getItem("@App:token");
    const storagedNavigation = localStorage.getItem("@App:navigation");
    const storagedConexaEmbed = localStorage.getItem("@App:conexa_embed");
   
    if (storagedToken && storagedUser) {
      setUser(JSON.parse(storagedUser));
      setNavigation(JSON.parse(storagedNavigation));
      setConexaEmbed(storagedConexaEmbed);
    }
  }, []);

  const getToken = () => {
    return localStorage.getItem("@App:token");
  }

  const ConfirmCode = async ({email, code}) => {
    return ApiService
      .post("confirm-code", {
        email: email,
        code: code
      })
  };

  const ChangePassword = async ({email, password}) => {
    return ApiService
      .post("change-password", {
        email: email,
        password: password
      })
  };

  const Forgot = async ({email}) => {
    return ApiService
      .post("forgot-password", {
        email: email
      });
  };

  const canViewFinancialData = () => {
    if (user?.role?.view_financial_data === 1) {
      return true;
    }

    return false;
  }

  const isOnlyView = () => {
    let view = false;
    navigation.map((nav) => {
      if (nav.submenu.length > 0) {
        return nav.submenu.map((sub) => {
          if (`/${sub.path}` === window.location.pathname) {
            if (sub.only_view === 1) {
              view = true;
            }
          }
        });
      } else {
        if (`/${nav.path}` === window.location.pathname) {
          if (nav.only_view === 1) {
            view =  true;
          }
        }
      }
    });
    
    return view;
  }

  const isMaster = () => {
    if (user?.role?.id === 1) {
      return true;
    }

    return false
  }

  const isPopupHumorActive = () => {
    return user?.isPopUpHumorActive === 1;
  }

  return (
    <AuthContext.Provider
      value={{ 
        signed: Boolean(user), 
        user, 
        setUser,
        Login, 
        Logout, 
        getToken, 
        loading,
        Forgot,
        ConfirmCode,
        ChangePassword,
        navigation,
        canViewFinancialData,
        isOnlyView,
        isMaster,
        conexaEmbed,
        isPopupHumorActive
      }}>
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);
  return context;
}

export default AuthContext;
