import React from 'react'
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from 'configs/AppConfig'

export const publicRoutes = [
    {
        key: 'login',
        path: `${AUTH_PREFIX_PATH}/login`,
        component: React.lazy(() => import('views/auth-views/authentication/login')),
    },
    {
        key: 'register',
        path: `${AUTH_PREFIX_PATH}/register`,
        component: React.lazy(() => import('views/auth-views/authentication/register')),
    },
    {
        key: 'forgot-password',
        path: `${AUTH_PREFIX_PATH}/forgot-password`,
        component: React.lazy(() => import('views/auth-views/authentication/forgot-password')),
    }
]

export const protectedRoutes = [
    {
        key: 'dashboard.default',
        path: `${APP_PREFIX_PATH}/dashboards/default`,
        component: React.lazy(() => import('views/app-views/dashboards/default')),
    },
    {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        component: React.lazy(() => import('views/app-views/users/index')),
    },
    {
        key: 'roles',
        path: `${APP_PREFIX_PATH}/roles`,
        component: React.lazy(() => import('views/app-views/roles/index')),
    },
    {
        key: 'companies',
        path: `${APP_PREFIX_PATH}/companies`,
        component: React.lazy(() => import('views/app-views/companies/index')),
    },
    {
        key: 'health-and-wellness',
        path: `${APP_PREFIX_PATH}/health-and-wellness`,
        component: React.lazy(() => import('views/app-views/health-and-wellness/index')),
    },
    {
        key: 'guidelines',
        path: `${APP_PREFIX_PATH}/guidelines`,
        component: React.lazy(() => import('views/app-views/guidelines/index')),
    },
    {
        key: 'humor',
        path: `${APP_PREFIX_PATH}/humor`,
        component: React.lazy(() => import('views/app-views/humor/index')),
    },
    {
        key: 'report-humor',
        path: `${APP_PREFIX_PATH}/report-humor`,
        component: React.lazy(() => import('views/app-views/reports/humor/index')),
    },
    
]